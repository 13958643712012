import React, { useState } from 'react';
import Snackbar from '../Snackbar/Snackbar';
import useConferenceMetadataQuery from '../../hooks/useConferenceMetadataQuery/useConferenceMetadataQuery';
/**
 * For now, simpler to make this notification guest/host agnostic, till we detect from any device whether the host
 * has already joined or not.
 */
export default function WaitingForHostNotification() {
  // Allow them to close the snackbar to access audio settings in mobile, behind it.
  const [isOpen, setIsOpen] = useState(true);
  const { data } = useConferenceMetadataQuery();
  const { is_interviewer } = data || {};

  return (
    <Snackbar
      variant="info"
      headline={
        is_interviewer === true
          ? "You're the only one in the conversation. Please wait ⌛"
          : 'You are the first one here. Please wait for your interviewer. ⌛'
      }
      message=""
      open={isOpen}
      handleClose={() => setIsOpen(false)}
    />
  );
}
