import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import makeStyles from '@material-ui/styles/makeStyles';
import { getInitials } from '../IntroContainer/UserMenu/UserAvatar/UserAvatar';

const useStyles = makeStyles({
  red: {
    color: 'white',
    backgroundColor: '#F24B4D',
  },
});

/**
 * Simpler version than the UserAvatar, requiring only the name that participants set in the RoomNameScreen.
 * Special-case phone numbers as the identity (for PSTN) to prevent having a weird "+" in the avatar, confusing.
 *
 * TODO: find an elegant way to surface the phone number (or part of it) to identify the speaker. Not pressing as
 * relevant only for multi-guest interviews with more than one guest joining via phone.
 */
export default function IdentityAvatar({
  iconFontSize = 'large',
  identity,
}: {
  iconFontSize?: 'inherit' | 'medium' | 'default' | 'large' | 'small' | undefined;
  identity: string;
}) {
  const classes = useStyles();

  const showPhoneIcon = identity.startsWith('+');
  const content = showPhoneIcon ? <PhoneIphoneIcon fontSize={'inherit'} /> : getInitials(identity);
  const avatarStyle = showPhoneIcon ? { fontSize: iconFontSize } : {};

  return (
    <Avatar className={classes.red} style={avatarStyle}>
      {content}
    </Avatar>
  );
}
