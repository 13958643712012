import { useContext } from 'react';
import { RoomNameContext } from '../../components/RoomNameProvider';

export default function useRoomNameContext() {
  const context = useContext(RoomNameContext);
  if (!context) {
    throw new Error('useRoomNameContext must be used within a RoomNameProvider');
  }
  return context;
}
