export const BACKGROUND_FILTER_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 640,
  height: 480,
  frameRate: 24,
};

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';

// Admin rooms can see the video feature.
// TODO: find a way to sync these with the allocated interview pins (e.g., use user-specific pins for the staff).
export const ADMIN_ROOM_PINS = ['1447'];

// TODO: improve this logic if we manage to pass env variables to the React (not Node) app.
export let ARTIFACT_MAIN_DOMAIN: string;
switch (window.location.hostname) {
  case 'localhost':
    ARTIFACT_MAIN_DOMAIN = 'http://localhost:8000';
    break;
  case 'conversation.heyartifact.com':
    ARTIFACT_MAIN_DOMAIN = 'https://www.heyartifact.com';
    break;
  default:
    ARTIFACT_MAIN_DOMAIN = 'https://staging.heyartifact.com';
}

export const ILLUSTRATIONS_S3_BUCKET = 'https://heyartifact-assets.s3-us-west-2.amazonaws.com/dashboard/illustrations/';
export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 3;
