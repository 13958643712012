import React from 'react';

interface TwilioLogoProps {
  className: string;
}

export default function TwilioLogo({ className }: TwilioLogoProps) {
  return (
    <div className={className} style={{ height: '30px' }}>
      <img
        src={'https://ik.imagekit.io/no6qhzlonix/production/public/logo/tr:w-800/logo-black.png'}
        alt="Artifact Logo"
        style={{ height: '100%' }}
      />
    </div>
  );
}
