import React from 'react';

import { ILLUSTRATIONS_S3_BUCKET } from '../../constants';
import { isMobile } from '../../utils';

export default function VideoLogo() {
  return (
    <img
      alt="Microphone illustration - white"
      src={`${ILLUSTRATIONS_S3_BUCKET}microphone-white.svg`}
      style={{ height: isMobile ? '80px' : '100px' }}
    />
  );
}
