const FRONT_END_ORIGINS = [
  'http://localhost:3000',
  'https://conversation-staging.heyartifact.com',
  'https://conversation.heyartifact.com',
];

/**
 * If this app is deployed as a Twilio function, don't change the URL because routing isn't supported.
 * Same for now with our domain as we're deploying via a Twilio function behind a reverse proxy...
 * N.B. Moved outside of the hook to make testing easier given its new structure.
 */
export function replaceHistoryStateIfNeeded(roomName: string) {
  const origin = window.location.origin;
  if (!origin.includes('twil.io') && !FRONT_END_ORIGINS.includes(origin)) {
    window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
  }
}

/**
 * Twilio serverless functions not supporting routing, GET params are a robust way to pre-populate the room name for
 * guests' convenience.
 */
export function extractPinParam() {
  const match = window.location.search.match(/pin=([0-9]{4,5})&?/);
  return match ? match[1] : '';
}

/**
 * Allow pre-populating guests' names from Calendly / interviewers' names from our back-office.
 */
export function extractNameParam() {
  const match = window.location.search.match(/name=([a-zA-Z\s%20]*)&?/);
  return match ? decodeURIComponent(match[1]) : '';
}
