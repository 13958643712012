import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

import { ARTIFACT_MAIN_DOMAIN } from '../../constants';
import { DEBUG } from '../../environment';
import { isValidRoomName } from '../../utils';
import useRoomNameContext from '../useRoomNameContext/useRoomNameContext';

import queryString from 'query-string';

const CONFERENCE_METADATA_QUERY_KEY = (conferencePin: string) => ['conferenceMetadata', conferencePin];
const CONFERENCE_METADATA_URL = (conferencePin: string, userEmail: string) => {
  const queryParameter = userEmail ? `?email=${encodeURIComponent(userEmail)}` : '';
  return `${ARTIFACT_MAIN_DOMAIN}/api/v1.0/conference-metadata/${conferencePin}/${queryParameter}`;
};

export default function useConferenceMetadataQuery(roomName?: string) {
  // If no specific pin is passed to this helper, default to the one corresponding to the room we're in.
  const { roomName: currentRoomName, setRoomNameError } = useRoomNameContext();
  const pin: string = currentRoomName || roomName!;
  const queryParams = queryString.parse(window.location.search);
  const email = queryParams ? (queryParams.email as string) : '';

  return useQuery(
    CONFERENCE_METADATA_QUERY_KEY(pin),
    () =>
      axios
        .get(CONFERENCE_METADATA_URL(pin, email), { withCredentials: true })
        .then(res => {
          setRoomNameError('');
          return res.data;
        })
        .catch(err => {
          const res = err.response;
          if (DEBUG) console.log(res);
          if (res && res.status !== 404) {
            Sentry.withScope(function(scope) {
              scope.setContext('Conference metadata query', { pin: pin, response: res });
              Sentry.captureMessage(`Conference metadata query failed because of ${err.message}`, 'error');
            });
          }
        }),
    { enabled: isValidRoomName(pin), refetchOnWindowFocus: false }
  );
}
