export function formatRemainingTime(minutes: number, seconds: number) {
  const numberFormattingParameters = {
    minimumIntegerDigits: 2,
    useGrouping: false,
  };
  const formattedMinutes = minutes.toLocaleString('en-US', numberFormattingParameters);
  const formattedSeconds = seconds.toLocaleString('en-US', numberFormattingParameters);

  return `${formattedMinutes}:${formattedSeconds}`;
}
