import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { confirmable } from 'react-confirm';
import theme from '../../theme';

interface ConfirmationProps {
  okLabel?: string;
  cancelLabel?: string;
  title?: string;
  confirmation: string;
  show: boolean;
  proceed: () => void;
  cancel: () => void;
}

/**
 * Confirmation component wrapped by `react-confirm` to produce a similar behavior as the `window.confirm()` function
 * while allowing some style configuration. The dialog is injected outside of the root div, hence the need to wrap
 * the dialog in the ThemeProvider.
 */
const Confirmation = ({
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  title = 'Confirmation',
  confirmation,
  show,
  proceed,
  cancel,
}: ConfirmationProps) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Dialog open={show} onClose={cancel} fullWidth={true} maxWidth="xs">
        <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
        <DialogContent>{confirmation}</DialogContent>
        <DialogActions>
          <Button variant="text" onClick={cancel}>
            {cancelLabel}
          </Button>
          <Button color="primary" variant="contained" onClick={proceed}>
            {okLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default confirmable(Confirmation);
