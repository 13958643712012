import React, { createContext, useState } from 'react';

import { isValidRoomName } from '../../utils';

const INVALID_PIN_FORMAT_ERROR: string = 'invalid_pin_format_error';
export const PIN_NOT_FOUND_ERROR: string = 'pin_not_found';

const ERROR_MESSAGES = {
  [INVALID_PIN_FORMAT_ERROR]: 'Please enter a 4- or 5-digit pin.',
  [PIN_NOT_FOUND_ERROR]: 'Incorrect pin. Please try again.',
};

type RoomNameContextType = {
  roomName: string;
  roomNameErrorMessage: string;
  setRoomName: (roomName: string) => void;
  setRoomNameError: (error: string) => void;
  validateRoomName: () => boolean;
};

export const RoomNameContext = createContext<RoomNameContextType>(null!);

/**
 * Make the room name available across the whole app, even when the VideoContext hasn't been initiated yet and the
 * Room not created yet.
 */
export const RoomNameProvider: React.FC = ({ children }) => {
  const [roomName, setRoomName] = useState<string>('');
  const [roomNameError, setRoomNameError] = useState<string | null>(null);
  const roomNameErrorMessage: string = roomNameError !== null ? ERROR_MESSAGES[roomNameError] : '';

  function validateRoomName(): boolean {
    const isValid: boolean = isValidRoomName(roomName);
    if (isValid) {
      setRoomNameError(null);
    } else {
      setRoomNameError(INVALID_PIN_FORMAT_ERROR);
    }
    return isValid;
  }

  return (
    <RoomNameContext.Provider
      value={{ roomName, roomNameErrorMessage, setRoomName, validateRoomName, setRoomNameError }}
    >
      {children}
    </RoomNameContext.Provider>
  );
};
