import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: `${theme.brand}CC`, // 80% opacity to notice the hover.
      },
    },
  })
);

interface EndCallButtonProps {
  className?: string;
  setShowSuccessScreen: (show: boolean) => void;
}

export default function EndCallButton({ className, setShowSuccessScreen }: EndCallButtonProps) {
  const classes = useStyles();
  const { room } = useVideoContext();

  const handleDisconnection = () => {
    setShowSuccessScreen(true);
    room!.disconnect();
  };

  return (
    <Button onClick={handleDisconnection} className={clsx(classes.button, className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
}
