import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import { DEBUG } from '../../environment';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { extractNameParam, extractPinParam, replaceHistoryStateIfNeeded } from './helpers';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useConferenceMetadataQuery from '../../hooks/useConferenceMetadataQuery/useConferenceMetadataQuery';
import useRoomNameContext from '../../hooks/useRoomNameContext/useRoomNameContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { PIN_NOT_FOUND_ERROR } from '../RoomNameProvider';
import { differenceInDays, parseISO } from 'date-fns';
import Confirmation from './Confirmation';
import { createConfirmation } from 'react-confirm';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { roomName, roomNameErrorMessage, setRoomName, setRoomNameError, validateRoomName } = useRoomNameContext();
  const { isError, isSuccess, data: conferenceMetadata } = useConferenceMetadataQuery(roomName);
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');

  const [mediaError, setMediaError] = useState<Error>();

  const nameParam = extractNameParam();
  const pinParam = extractPinParam();

  // N.B. Routing not being supported yet, the use cases for actual URL params are limited.
  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [setRoomName, user, URLRoomName]);

  useEffect(() => {
    if (nameParam) setName(nameParam);
  }, [nameParam, setName]);

  useEffect(() => {
    if (pinParam) setRoomName(pinParam);
  }, [pinParam, setRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        if (DEBUG) {
          console.log('Error acquiring local media:');
          console.dir(error);
        }
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const confirm = createConfirmation(Confirmation);
  const outdatedPinConfirmation = useCallback(
    () =>
      confirm({
        title: 'This conversation is supposed to occur on a different day',
        confirmation:
          "If you have multiple interviews, please confirm that you've entered the PIN for today's interview before continuing.",
        okLabel: 'Join',
      }),
    [confirm]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const isRoomNameValid: boolean = validateRoomName();
      const isPinValid: boolean = isSuccess;
      if (isError) {
        setRoomNameError(PIN_NOT_FOUND_ERROR);
      }
      const proceed = () => {
        replaceHistoryStateIfNeeded(roomName);
        setStep(Steps.deviceSelectionStep);
      };
      // If the pin is valid as well as the room name, check that the interview start time is within the same day,
      // otherwise, ask the user to confirm.
      if (isRoomNameValid && isPinValid) {
        const startTime = conferenceMetadata ? conferenceMetadata.start_time : null;
        const startsOnSameDay = startTime && Math.abs(differenceInDays(parseISO(startTime), new Date())) < 1;
        if (startTime && !startsOnSameDay) {
          outdatedPinConfirmation().then(proceed);
        } else {
          proceed();
        }
      }
    },
    [validateRoomName, isSuccess, roomName, outdatedPinConfirmation, conferenceMetadata]
  );

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          roomNameErrorMessage={roomNameErrorMessage}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
