import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { ARTIFACT_MAIN_DOMAIN, ILLUSTRATIONS_S3_BUCKET } from '../../constants';
import { isMobile } from '../../utils';
import useConferenceMetadataQuery from '../../hooks/useConferenceMetadataQuery/useConferenceMetadataQuery';
import useRoomNameContext from '../../hooks/useRoomNameContext/useRoomNameContext';

// TODO: use URLSearchParams and uninstall the `query-string` package.
import queryString from 'query-string';

const SPACING = 4;

interface SuccessScreenProps {
  setShowSuccessScreen: (show: boolean) => void;
}

export default function SuccessScreen({ setShowSuccessScreen }: SuccessScreenProps) {
  const { data, isLoading } = useConferenceMetadataQuery();
  const { is_interviewer } = data || {};
  const showGuestContent = is_interviewer === false;

  const recordIntroduction = () => {
    // Set name to `introduction`, the convention for the audio file containing the intro audio.
    const queryParams = queryString.parse(window.location.search);
    queryParams.name = 'introduction';
    window.location.search = queryString.stringify(queryParams);
    setShowSuccessScreen(false);
  };

  const classes = useConferenceEndStyles();

  if (isLoading) return <CircularProgress style={{ marginLeft: '50vw', marginTop: '25vh' }} />;

  const paddingTop = isMobile ? '15vh' : '20vh';

  return (
    <div className={classes.root} style={{ paddingTop: paddingTop }}>
      <Container maxWidth="sm" className={classes.container}>
        {showGuestContent ? <GuestContent /> : <InterviewerContent recordIntroduction={recordIntroduction} />}
      </Container>
    </div>
  );
}

/**
 * TODO: get the user display's name and implement tracking!
 */
function GuestContent() {
  const { roomName: pin } = useRoomNameContext();
  const theme = useTheme();
  const {
    data: { episode_slug, paid, is_producer },
    isLoading,
  } = useConferenceMetadataQuery();

  // Send users to their dashboard – even if it's two steps – as we can't easily map to the relevant Episode yet.
  const handleAddPhotosClick = () => {
    const path = episode_slug ? `/creations/${episode_slug}/` : '/dashboard/';
    window.location.href = `${ARTIFACT_MAIN_DOMAIN}${path}`;
  };

  const circularProgress = (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress style={{ marginTop: '25vh' }} />
    </div>
  );

  if (isLoading) {
    return circularProgress;
  } else if (!paid && is_producer) {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get('email') || '';
    window.location.href = `${ARTIFACT_MAIN_DOMAIN}/post-interview/${pin}/?email=${encodeURIComponent(email)}`;
    return circularProgress;
  } else {
    return (
      <>
        <Typography variant="body1" gutterBottom>
          Thanks for joining!
        </Typography>
        <>
          <Typography variant="h4" gutterBottom>
            Your Artifact is on its way!
          </Typography>
          <Typography style={{ marginTop: theme.spacing(SPACING) }} variant="body1" gutterBottom>
            Continue bringing your story to life by adding photos and videos to your episode.
          </Typography>
          <Button
            color="primary"
            onClick={handleAddPhotosClick}
            style={{ marginTop: theme.spacing(SPACING) }}
            variant="contained"
          >
            Add episode media
          </Button>
        </>
      </>
    );
  }
}

interface InterviewerContentProps {
  recordIntroduction: Function;
}

function InterviewerContent({ recordIntroduction }: InterviewerContentProps) {
  const theme = useTheme();
  const {
    data: { is_schedule_before_you_pay },
  } = useConferenceMetadataQuery();

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Thank you!
      </Typography>
      <div style={{ marginTop: theme.spacing(SPACING) }}>
        {is_schedule_before_you_pay ? (
          <>
            <Typography variant="subtitle1" gutterBottom>
              No need to record an introduction for this interview!
            </Typography>
            <Typography variant="subtitle1">
              If multiple kids were talked about in this interview, please make sure you complete all of the interviews
              in the back office (there is one interview per child).
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="subtitle1" gutterBottom>
              Please record your introduction now.
            </Typography>
            <div style={{ marginTop: theme.spacing(SPACING) }}>
              <Button color="primary" onClick={() => recordIntroduction()} variant="contained">
                Record Intro
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

const useConferenceEndStyles = makeStyles(() => ({
  root: {
    backgroundImage: `url(${ILLUSTRATIONS_S3_BUCKET}background_waves.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  container: {
    margin: 'auto',
    textAlign: 'center',
    width: '80%',
  },
}));
