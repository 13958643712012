import React from 'react';
import { useStopwatch } from 'react-timer-hook';
import Chip from '@material-ui/core/Chip';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { useTheme } from '@material-ui/core/styles';
import { formatRemainingTime } from './utils';

export default function Timer() {
  const theme = useTheme();

  const { seconds, minutes, isRunning, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  const timerHasStarted = !(minutes === 0 && seconds === 0);
  const chipStyle = { margin: theme.spacing(0, 0.5) };
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: 315 }}>
      <Chip
        icon={<AccessAlarmIcon />}
        label={formatRemainingTime(minutes, seconds)}
        color={minutes === 0 && seconds === 0 ? 'primary' : 'secondary'}
        style={chipStyle}
      />
      {!isRunning && !timerHasStarted && <Chip label="Start" onClick={start} style={chipStyle} />}
      {!isRunning && timerHasStarted && <Chip label="Resume" onClick={start} style={chipStyle} />}
      {!isRunning && timerHasStarted && <Chip label="Restart" style={chipStyle} onClick={() => reset()} />}
      {isRunning && <Chip label="Pause" onClick={pause} style={chipStyle} />}
    </div>
  );
}
